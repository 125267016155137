import * as React from "react"

function SvgCroix(props) {
  return (
    <svg width={31.707} height={31.695} viewBox="0 0 31.707 31.695" {...props}>
      <g data-name="Groupe 4004" fill="none" stroke="#707070">
        <path data-name="Ligne 144" d="M.354.354l31 30.987" />
        <path data-name="Ligne 145" d="M31.354.354l-31 30.987" />
      </g>
    </svg>
  )
}

export default SvgCroix